<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="footer-keyword">
          <div class="footer-bullet">
            <a href="#" onclick="handleOnClick()">
              <em>개발<br>가이드</em>
              <span></span>
            </a>
          </div>
          <div class="footer-bullet">
            <a href="https://agency.payhub.co.kr" target="_blank">
              <em>리셀러</em>
              <span></span>
            </a>
          </div>
          <div class="footer-bullet">
            <a href="https://franchisee.payhub.co.kr" target="_blank">
              <em>가맹점</em>
              <span></span>
            </a>
          </div>
        </div>
        <div class="footer-info-wrap">
          <div class="footer_adress">
            <h5>Copyrights © 2024 PAYHUB. All Rights Reserved.</h5>
            <div class="pc_footer">
              <ul>
                <li>(주)페이허브</li>
                <li>대표 : 이정환</li>
                <li>서울특별시 송파구 오금로35길16, 2층(오금동,신신빌딩)</li>
              </ul>
              <ul>
                <li>사업자등록번호 : 673-86-01311</li>
                <li>통신판매업신고번호 : 제2025-서울송파-0708호</li>
                <li>대표번호 : 1661-0447</li>
                <li>FAX : 02-6305-0445</li>
              </ul>
              <ul>
                <li>서비스 계약 문의 : <a href="mailto:cs@payhub.co.kr" class="mail">cs@payhub.co.kr</a></li>
                <li>제휴 문의 : <a href="mailto:cs@payhub.co.kr" class="mail">cs@payhub.co.kr</a></li>
                <li>기술 지원 : <a href="mailto:tech@payhub.co.kr" class="mail">tech@payhub.co.kr</a></li>
              </ul>
            </div>
            <ul class="m_footer">
              <li>(주)페이허브 | 대표 : 이정환</li>
              <li>서울특별시 송파구 오금로35길16, 2층(오금동,신신빌딩)</li>

              <li>사업자등록번호 : 673-86-01311</li>
              <li>통신판매업신고번호 : 제2025-서울송파-0708호</li>
              <li>대표번호 : 1661-0447</li>
              <li>FAX : 02-6305-0445</li>
              <li>서비스 계약 문의 : <a href="mailto:cs@payhub.co.kr" class="mail">cs@payhub.co.kr</a></li>
              <li>제휴 문의 : <a href="mailto:cs@payhub.co.kr" class="mail">cs@payhub.co.kr</a></li>
              <li>기술 지원 : <a href="mailto:tech@payhub.co.kr" class="mail">tech@payhub.co.kr</a></li>
            </ul>
          </div>
        </div>
        <p class="txt">전자지급결제대행업 : 02-004-00162</p>
        <p class="txt2">페이허브 기본약관</p>
        <div class="footer-link-wrap">
          <ul class="footer_link">
            <li>
              <a href="/terms/use">서비스 이용약관</a>
            </li>
            <li>
              <a href="/terms/privacy">개인정보 처리방침</a>
            </li>
            <li>
              <a href="/terms/financial">전자금융거래 기본약관</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter"
}
</script>

<style scoped>

</style>